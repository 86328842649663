export const environment = {
  production: true,
  apiUrl: "https://umyoentertainment.site/api_umyocards/public/api/"
};


// export const environment = {
//   production: false,
//   apiUrl: "http://127.0.0.1:8000/api/"
// };
